export const facilityFormVO = function () {
  return {
    facilityInfo: {
      area: {
        id: null
      },
      department: {
        id: null
      },
      location: '',
      name: '',
      orgId: 0,
      plotType: '',
      remarks: '',
      facilityType: 0
    },
    imageIds: [],
    images: [],
    fileIds: [],
    files: []
  }
}