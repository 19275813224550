<template>
  <div>
    <a-button @click="toSelectDevice()" class="m-l-6" type="primary" style="margin-bottom: 10px;margin-left: 814px;">添加设备</a-button>
    <a-table
      size="middle"
      :columns="columns"
      :dataSource="tableData"
      :loading="tableLoading"
      :pagination="pagination"
      @change="tableChange"
      :rowKey="record => record.id"
      bordered>
      <template slot="_index" slot-scope="text, record, index">
        {{searchParams.number*searchParams.size+index+1}}
      </template>
      <template slot="action" slot-scope="text, record">
        <a @click="handleRemove(record.id)">移除</a>
      </template>
    </a-table>
    <a-modal title="设备选择列表"
             :width="1000"
             :footer="null"
             :visible="selectDeviceModal"
             @cancel="closeDeviceModal">
      <device ref="selectDeviceRef" :facilityId="facilityId"></device>
    </a-modal>
  </div>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import { deviceColumns } from './common/common'
  import Device from './SelectDeviceList'
  export default {
    components: { Device },
    mixins: [],
    name: 'DeviceList',
    data() {
      return {
        sup_this: this,
        tableData: [],
        searchParams: {
          facilityId: null,
          number: 0,
          size: 10,
        },
        visible: false,
        editVisible: false,
        gateWayList: [],
        configList: [],
        columns: deviceColumns(),
        pagination: {
          total: 0,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`
        },
        facilityId: null,
        tableLoading: false,
        selectDeviceModal: false
      }
    },
    created() {
    },
    methods: {
      // 设施包含的设备分页列表数据
      searchDevice(id) {
        this.facilityId = id;
        this.searchParams.facilityId = id;
        this.$http(this, {
          url: SERVICE_URLS.equipment2.facilityApi.searchDevice,
          noTips: true,
          loading: 'tableLoading',
          data: this.searchParams,
          success: (data) => {
            this.tableData = data.body.content;
            this.pagination.total = data.body.totalElements;
          }
        })
      },
      // 移除操作
      handleRemove(id) {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.facilityApi.removeDevice,
          params: {
            deviceId: id
          },
          success: (data) => {
            this.searchDevice(this.facilityId)
          },
          error: (e) => {
          }
        })
      },
      // 设备选择
      toSelectDevice () {
        this.selectDeviceModal = true;
        this.$nextTick(() => {
          this.$refs.selectDeviceRef.selectDevice();
        })
      },
      closeDeviceModal() {
        this.searchDevice(this.facilityId);
        this.selectDeviceModal = false;
      },
      tableChange(pagination, filters, sorter) {
        this.searchParams.size = pagination.pageSize;
        this.searchParams.number = pagination.current - 1;
        this.searchDevice(this.facilityId);
      },
    }
  }
</script>

<style scoped>
</style>