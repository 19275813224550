<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="5">
        <a-tree :treeData="treeData" :showLine="true" v-if="treeData.length" :defaultExpandedKeys="['0-0']"  @select="selected">
          <template slot="custom" slot-scope="item">
            <span :title="item.title">{{ item.title.length > 11?item.title.substring(0,11) + '...': item.title}}</span>
          </template>
        </a-tree>
      </a-col>
      <a-col :span="19" class="content-right">
        <div class="fs-search-box">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-form-item label="设备名称">
                <a-input v-model="searchParams.name" placeholder="请输入" allowClear/>
              </a-form-item>
              <a-form-item label="设备编号">
                <a-input v-model="searchParams.no" placeholder="请输入" allowClear/>
              </a-form-item>
              <a-form-item label="所属部门">
                <a-tree-select
                  style="width: 196px"
                  :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                  :treeData="orgTree"
                  allowClear
                  placeholder="请选择"
                  v-model="searchParams.departmentId"
                ></a-tree-select>
              </a-form-item>
              <a-form-item class="f-r m-r-none">
                <a-button type="primary" @click="selectDevice" icon="search">查询</a-button>
              </a-form-item>
            </a-form>
          </div>
        </div>
        <a-table
          size="middle"
          :columns="columns"
          :dataSource="tableData"
          :loading="tableLoading"
          :pagination="pagination"
          @change="tableChange"
          :rowKey="record => record.id"
          bordered>
          <template slot="_index" slot-scope="text, record, index">
            {{searchParams.number*searchParams.size+index+1}}
          </template>
          <template slot="action" slot-scope="text, record">
            <a @click="handleCheck(record.id)">选择</a>
          </template>
        </a-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import { deviceColumns } from './common/common'
  export default {
    components: {  },
    mixins: [],
    name: 'SelectDeviceList',
    props: {
      facilityId: {
        type: Number,
        default: null
      },
    },
    data() {
      return {
        sup_this: this,
        tableData: [],
        searchParams: {
          number: 0,
          size: 10,
          // 设备分类ID
          categoryId: null,
          // 设备名称
          name: '',
          // 设备编号
          no: '',
          // 部门ID
          departmentId: null
        },
        columns: deviceColumns(),
        pagination: {
          total: 0,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`
        },
        tableLoading: false,
        orgTree: [],
        deviceIds: [],
        treeData: []
      }
    },
    created() {
      this.getCategoryTree();
      this.getOrgTree()
    },
    methods: {
      // 获取选择设备的分页列表数据
      selectDevice() {
        if (!this.searchParams.categoryId) {
          this.searchParams.categoryId = null
        }
        this.$http(this, {
          url: SERVICE_URLS.equipment2.facilityApi.selectDevice,
          noTips: true,
          loading: 'tableLoading',
          data: this.searchParams,
          success: (data) => {
            this.tableData = data.body.content;
            this.pagination.total = data.body.totalElements;
          }
        })
      },
      // 选择单个设备
      handleCheck(id) {
        this.deviceIds = [];
        this.deviceIds.push(id);
        this.$http(this, {
          url: SERVICE_URLS.equipment2.facilityApi.saveMappings,
          data: this.deviceIds,
          params: {
            id: this.facilityId
          },
          success: (data) => {
          },
          error: (e) => {
          }
        })
      },
      tableChange(pagination, filters, sorter) {
        this.searchParams.size = pagination.pageSize;
        this.searchParams.number = pagination.current - 1;
        this.selectDevice();
      },
      // 获取部门树数据
      getOrgTree () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.orgTree = data.body
          }
        })
      },
      // 获取设备类型树
      getCategoryTree() {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentTypeApi.search,
          noTips: true,
          success: (data) => {
            this.treeData.push(data.body);
            this.treeData[0].key = 0;
          }
        })
      },
      // 点击设备类型
      selected(selectedKeys) {
        this.searchParams.categoryId = selectedKeys[0];
        this.selectDevice();
      },
    }
  }
</script>

<style scoped>
  .content-right{
    border-left: 1px solid rgba(233, 233, 233, 1);
  }
</style>